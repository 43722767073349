import { Outlet } from 'react-router-dom';

import { InitializationState, useZoomStore } from '~/lib/zoom/state';

export const Call = () => {
  const initializationState = useZoomStore((store) => store.initializationState);

  if (initializationState === InitializationState.Initializing) {
    return <div>Initializing...</div>;
  }

  if (initializationState === InitializationState.Error) {
    return <div>Failed to initialize Zoom.</div>;
  }

  return <Outlet />;
};
