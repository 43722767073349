import { Formik } from 'formik';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from '~/design/components/forms/Form';
import { SubmitButton } from '~/design/components/forms/SubmitButton';
import { TextField } from '~/design/components/forms/TextField';
import type { FormOnSubmit } from '~/design/components/forms/types';

export const JoinCallScene = () => {
  const navigate = useNavigate();

  const onSubmit: FormOnSubmit<{
    topic: string;
  }> = useCallback(
    async (values) => {
      navigate(`/call/${values.topic}`);
    },
    [navigate],
  );

  return (
    <Formik
      initialValues={{
        topic: '',
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <TextField name='topic' label='Topic' />
        <SubmitButton>Join</SubmitButton>
      </Form>
    </Formik>
  );
};
