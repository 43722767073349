import styled from '@emotion/styled';
import type { Participant } from '@zoom/videosdk';
import { forwardRef } from 'react';
import type { ReactNode } from 'react';

import { ParticipantVideo } from './ParticipantVideo';
import { HoneycombLayout } from './layouts/HoneycombLayout';
import { ParticipantLayoutItem, ParticipantLayoutRoot } from './layouts/ParticipantLayoutRenderer';

export const HoneycombGrid = forwardRef<HTMLDivElement, { children: ReactNode; className?: string }>(
  (
    props: {
      children: ReactNode;
      className?: string;
    },
    ref,
  ) => <ParticipantLayoutRoot ref={ref} Layout={HoneycombLayout} {...props} />,
);
HoneycombGrid.displayName = 'HoneycombGrid';

export const HoneycombItem = forwardRef<
  HTMLDivElement,
  {
    participant: Participant;
    index: number;
    isEmptySeat?: boolean;
  }
>(({ participant, index, isEmptySeat, ...rest }, outerRef) => (
  <HoneycombLayoutItem participantId={participant.userId} index={index} ref={outerRef} {...rest}>
    <HoneycombParticipantBubble participant={participant} emptySeat={!!isEmptySeat} />
  </HoneycombLayoutItem>
));
HoneycombItem.displayName = 'HoneycombItem';

const HoneycombLayoutItem = styled(ParticipantLayoutItem)({
  'transform': 'translate(-50%, -50%)',
  'transition': '0.2s ease-out transform, 0.2s ease-out left, 0.2s ease-out top',
  '&[data-promoted="true"]': {
    transform: 'none',
  },
});

const HoneycombParticipantBubble = styled(ParticipantVideo)({
  width: '100%',
  height: '100%',
  borderRadius: '100%',

  variants: {
    promoted: {
      true: {
        borderRadius: '$card',
      },
      false: {
        borderRadius: '100%',
      },
    },
  },

  defaultVariants: {
    promoted: false,
  },
});
