import { useCallback } from 'react';

import { logger } from '~/lib/logger';

/**
 * Transforms an async callback (with no return value) into one that can be used
 * by a component that expects a synchronous callback.
 *
 * This memoizes `callback` – just like `useCallback` – and also captures any
 * errors thrown by it, so that they're not swallowed.
 */
export function useAsyncCallback<TParameters extends [...unknown[]]>(
  callback: (...args: TParameters) => Promise<void>,
  dependencies: unknown[],
): (...args: TParameters) => void {
  return useCallback((...args: TParameters) => {
    callback(...args).catch((error) => {
      logger.error(`Unexpected error in async callback:`, error);
    });
  }, dependencies);
}
