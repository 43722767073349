import {
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValue,
} from '~/design/components/Select';
import { useAsyncCallback } from '~/hooks/useAsyncCallback';
import { useZoomStore } from '~/lib/zoom/state';

export interface MicSelectorProps {
  showValue?: boolean;
}

export const MicSelector = ({ showValue }: MicSelectorProps) => {
  const mics = useZoomStore((store) => store.devices.mics);
  const value = useZoomStore((store) => store.devices.activeMicId);
  const client = useZoomStore((store) => store.client);

  const onMicChange = useAsyncCallback(
    async (id: string) => {
      await client.getMediaStream().switchMicrophone(id);
    },
    [client],
  );

  return (
    <SelectRoot value={value} onValueChange={onMicChange}>
      <SelectTrigger aria-label='Select a microphone'>
        {showValue ? (
          <SelectValue placeholder='Select a microphone' />
        ) : (
          <SelectValue asChild>
            <div />
          </SelectValue>
        )}
        <SelectIcon>▼</SelectIcon>
      </SelectTrigger>
      <SelectContent>
        {/* Ensure one item at least is always shown */}
        {!mics.some((mic) => mic.deviceId === 'default') && <SelectItem value='default'>Default</SelectItem>}
        {mics.map((mic) => (
          <SelectItem value={mic.deviceId} key={mic.deviceId}>
            {mic.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};
