import { gql } from '@apollo/client';

import { useHelloQuery } from '~/.generated/graphql';

export const Hello = () => {
  const { data } = useHelloQuery();

  return <div>The time is {data?.playground.now} from GraphQL</div>;
};

Hello.query = gql`
  query Hello {
    playground {
      now
    }
  }
`;
