import styled from '@emotion/styled';
import * as SelectPrimitive from '@radix-ui/react-select';
import { forwardRef } from 'react';

export const SelectTrigger = styled(SelectPrimitive.Trigger)({
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 32,
});

export const SelectIcon = styled(SelectPrimitive.Icon)({
  color: 'inherit',
});

const InternalSelectContent = styled(SelectPrimitive.Content)({
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.1)',
});

const InternalSelectViewport = styled(SelectPrimitive.Viewport)({
  padding: 8,
});

const InternalSelectItem = styled(SelectPrimitive.Item)({
  'display': 'flex',
  'alignItems': 'start',
  'height': 25,
  'padding': '0 35px 0 25px', // leaves room for indicator
  'position': 'relative',
  'userSelect': 'none',

  '&[data-disabled]': {
    color: 'rgba(0, 0, 0, 0.2)',
  },

  '&[data-highlighted]': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});

const InternalItemIndicator = styled(SelectPrimitive.ItemIndicator)({
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SelectItem = forwardRef<HTMLDivElement, SelectPrimitive.SelectItemProps>(
  ({ children, ...props }, ref) => (
    <InternalSelectItem ref={ref} {...props}>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <InternalItemIndicator>
        {/* You'd normally have a check icon here or something */}
        <span>✓</span>
      </InternalItemIndicator>
    </InternalSelectItem>
  ),
);
SelectItem.displayName = 'SelectItem';

export const SelectLabel = styled(SelectPrimitive.Label)({
  padding: '0 24px',
  fontSize: 12,
  lineHeight: 1,
});

export const SelectSeparator = styled(SelectPrimitive.Separator)({
  height: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  margin: 5,
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: 'inherit',
  cursor: 'default',
};
const InternalSelectScrollUpButton = styled(SelectPrimitive.ScrollUpButton)(scrollButtonStyles);
const InternalSelectScrollDownButton = styled(SelectPrimitive.ScrollDownButton)(scrollButtonStyles);

export const SelectContent = forwardRef<HTMLDivElement, SelectPrimitive.SelectContentProps>(
  ({ children, ...props }, ref) => (
    <SelectPrimitive.Portal>
      <InternalSelectContent {...props} ref={ref}>
        <InternalSelectScrollUpButton>
          {/* TODO: up icon */}
          <span>^</span>
        </InternalSelectScrollUpButton>
        <InternalSelectViewport>{children}</InternalSelectViewport>
        <InternalSelectScrollDownButton>
          {/* TODO: down icon */}
          <span>v</span>
        </InternalSelectScrollDownButton>
      </InternalSelectContent>
    </SelectPrimitive.Portal>
  ),
);
SelectContent.displayName = 'SelectContent';

export const SelectRoot = SelectPrimitive.Root;
export const SelectValue = SelectPrimitive.Value;
