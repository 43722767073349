import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import { useZoomStore } from '~/lib/zoom/state';

export const ScreenShareToggleButton = () => {
  const [isScreenshareOn, startScreenShare, stopScreenShare] = useZoomStore(
    (store) => [!!store.localMedia.screenShare, store.startScreenShare, store.stopScreenShare],
    shallow,
  );

  const onClick = useCallback(async () => {
    if (isScreenshareOn) {
      await stopScreenShare();
    } else {
      await startScreenShare();
    }
  }, [isScreenshareOn, startScreenShare, stopScreenShare]);

  return (
    /* eslint-disable-next-line @typescript-eslint/no-misused-promises -- what
    else am I supposed to do here, handler is async */
    <button onClick={onClick} type='button'>
      {isScreenshareOn ? 'Stop Screen Share' : 'Start Screen Share'}
    </button>
  );
};
