import { ConnectionState } from '@zoom/videosdk';
import { Formik } from 'formik';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Form } from '~/design/components/forms/Form';
import { SubmitButton } from '~/design/components/forms/SubmitButton';
import { TextField } from '~/design/components/forms/TextField';
import type { FormOnSubmit } from '~/design/components/forms/types';
import { AudioToggleButton } from '~/features/mediaDevices/AudioToggleButton';
import { CameraSelector } from '~/features/mediaDevices/CameraSelector';
import { MicSelector } from '~/features/mediaDevices/MicSelector';
import { ScreenShareToggleButton } from '~/features/mediaDevices/ScreenShareToggleButton';
import { VideoToggleButton } from '~/features/mediaDevices/VideoToggleButton';
import { VideoGrid } from '~/features/video/VideoGrid';
import { useZoomStore } from '~/lib/zoom/state';

export const CallScene = () => {
  const status = useZoomStore((store) => store.connectionState);

  if (status === ConnectionState.Closed) {
    return <ConnectToCall />;
  }

  if (status === ConnectionState.Reconnecting) {
    return <div>Reconnecting...</div>;
  }

  if (status === ConnectionState.Fail) {
    return <div>Failed to connect to Zoom.</div>;
  }

  return <ActiveCall />;
};

const ConnectToCall = () => {
  const { topic } = useParams();

  if (!topic) {
    throw new Error('CallScene must be rendered in a route with a topic parameter');
  }

  const join = useZoomStore((store) => store.join);

  const onSubmit: FormOnSubmit<{
    name: string;
    password: string;
  }> = useCallback(
    async (values) => {
      await join({
        topic,
        name: values.name,
        password: values.password,
      });
    },
    [join],
  );

  return (
    <Formik
      initialValues={{
        name: 'test',
        password: 'test',
      }}
      onSubmit={onSubmit}
    >
      <Form>
        <TextField name='name' label='Name' />
        <TextField name='password' label='Password' />
        <SubmitButton>Join</SubmitButton>
      </Form>
    </Formik>
  );
};

const ActiveCall = () => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      flex: 1,
      alignItems: 'center',
    }}
  >
    <div>
      <h1>Active call</h1>
      <div css={{ display: 'flex', gap: '8px' }}>
        <VideoToggleButton />
        <CameraSelector />
        <AudioToggleButton />
        <MicSelector />
        <ScreenShareToggleButton />
      </div>
    </div>
    <VideoGrid />
  </div>
);
