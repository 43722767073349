import { VideoCapturingState, VideoQuality } from '@zoom/videosdk';
import type { Participant } from '@zoom/videosdk';
import { useEffect, useState } from 'react';

import { logger } from '~/lib/logger';
import { useZoomStore } from '~/lib/zoom/state';

export interface ParticipantVideoProps {
  participant: Participant;
  promoted?: boolean;
  emptySeat?: boolean;
}

export const ParticipantVideo = ({ participant, promoted, emptySeat }: ParticipantVideoProps) => {
  const { zoomControlledCanvasRef } = useRenderParticipantVideo(participant);

  return (
    <div
      css={{
        borderRadius: promoted ? '32px' : '100%',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '3rem',
          backgroundColor: emptySeat ? 'transparent' : '#eee',
          border: emptySeat ? `inset 2px dashed #ccc` : 'none',
          borderRadius: '100%',
        }}
      >
        {participant.displayName[0]}
      </div>
      {!emptySeat && (
        <canvas
          ref={zoomControlledCanvasRef}
          width={1280}
          height={720}
          css={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            zIndex: 2,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </div>
  );
};

function useRenderParticipantVideo(participant: Participant) {
  const [zoomControlledCanvas, setZoomControlledCanvas] = useState<HTMLCanvasElement | null>(null);
  const client = useZoomStore((store) => store.client);

  const participantId = participant.userId;

  const [isUserVideoOn, setIsUserVideoOn] = useState(participant.bVideoOn);

  // if this is a remote participant, listen for video state changes
  useEffect(() => {
    function onVideoChange({ userId, action }: { action: 'Start' | 'Stop'; userId: number }) {
      if (userId !== participantId) return;
      setIsUserVideoOn(action === 'Start');
    }
    client.on('peer-video-state-change', onVideoChange);

    return () => {
      client.off('peer-video-state-change', onVideoChange);
    };
  }, [participantId, client]);
  // if this is the local participant, listen for capture state changes
  useEffect(() => {
    const localUser = client.getCurrentUserInfo();
    if (localUser.userId !== participantId) return;

    function onVideoChange({ state }: { state: VideoCapturingState }) {
      setIsUserVideoOn(state === VideoCapturingState.Started);
    }

    client.on('video-capturing-change', onVideoChange);

    return () => {
      client.off('local-video-state-change', onVideoChange);
    };
  }, [participantId, client]);

  useEffect(() => {
    if (!zoomControlledCanvas) return;
    if (!isUserVideoOn) return;

    const source = zoomControlledCanvas;

    client
      .getMediaStream()
      .renderVideo(
        source,
        participantId,
        zoomControlledCanvas.width,
        zoomControlledCanvas.height,
        0,
        0,
        VideoQuality.Video_720P,
      )
      .catch((err) => {
        logger.error(err);
      });

    return () => {
      client
        .getMediaStream()
        .stopRenderVideo(source, participantId)
        .catch((err) => {
          logger.error(err);
        });
    };
  }, [zoomControlledCanvas, participantId, client, isUserVideoOn]);

  return {
    zoomControlledCanvasRef: setZoomControlledCanvas,
  };
}
