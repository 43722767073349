import { useEffect, useRef } from 'react';

import { logger } from '~/lib/logger';
import { useZoomStore } from '~/lib/zoom/state';

export interface ScreenShareVideoProps {
  className?: string;
}

/**
 * There can be only one active screenshare in any Zoom meeting,
 * and this component will render it - whether it is yours or
 * someone else's.
 */
export const ScreenShareVideo = (props: ScreenShareVideoProps) => {
  const hasLocalScreenShare = useZoomStore((store) => !!store.localMedia.screenShare);

  if (hasLocalScreenShare) {
    return <LocalScreenShareVideo {...props} />;
  } else {
    return <RemoteScreenShareVideo {...props} />;
  }
};

const LocalScreenShareVideo = (props: ScreenShareVideoProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const screenShareElement = useZoomStore((store) => store.localMedia.screenShare);

  /**
   * This effect subscribes to changes in the local screenshare element
   * and injects it into the container div.
   */
  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    if (screenShareElement) {
      container.innerHTML = '';
      // make sure element isn't hidden
      screenShareElement.remove();
      screenShareElement.style.removeProperty('display');

      // this only works for video elements...
      screenShareElement.style.width = '100%';
      screenShareElement.style.height = '100%';
      screenShareElement.style.objectFit = 'contain';

      container.appendChild(screenShareElement);

      return () => {
        container.removeChild(screenShareElement);
      };
    }

    return undefined;
  }, [screenShareElement]);

  if (!screenShareElement) {
    return null;
  }

  return <div ref={containerRef} {...props} />;
};

const RemoteScreenShareVideo = (props: ScreenShareVideoProps) => {
  const sharingUserId = useZoomStore((store) => store.screenShareUserId);
  const client = useZoomStore((store) => store.client);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    if (!sharingUserId) return;

    client
      .getMediaStream()
      .startShareView(canvas, sharingUserId)
      .catch((err) => {
        logger.error(err);
      });

    return () => {
      client
        .getMediaStream()
        .stopShareView()
        .catch((err) => logger.error(err));
    };
  }, [sharingUserId, client]);

  if (!sharingUserId) {
    return null;
  }

  return (
    <canvas
      ref={canvasRef}
      width='1920'
      height='1080'
      css={{
        objectFit: 'contain',
        width: '100%',
        height: '100%',
      }}
      {...props}
    />
  );
};
