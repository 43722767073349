/* eslint-disable no-console -- temporary */

// stub logger we can extend or conditionally
// disable later.

export const logger = {
  log: console.log,
  warn: console.warn,
  error: console.error,
  debug: console.debug,
  info: console.info,
};

/* eslint-enable no-console */
