import { Form as FormikForm } from 'formik';
import type { FormikFormProps } from 'formik';

export const Form = (props: FormikFormProps) => (
  <FormikForm
    css={{
      display: 'flex',
      flexDirection: 'column',
      margin: 'initial',
    }}
    {...props}
  />
);
