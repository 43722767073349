import { useCallback } from 'react';
import type { MutableRefObject, Ref } from 'react';

/**
 * Create a ref callback that updates any number of other refs.
 */
export function useMergedRef<TElement>(...refs: Ref<TElement>[]): Ref<TElement> {
  return useCallback(
    (node: TElement) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref != null) {
          (ref as MutableRefObject<TElement | null>).current = node;
        }
      });
    },
    [refs],
  );
}
