import { VideoCapturingState } from '@zoom/videosdk';

import { useAsyncCallback } from '~/hooks/useAsyncCallback';
import { useZoomStore } from '~/lib/zoom/state';

export const VideoToggleButton = () => {
  const client = useZoomStore((store) => store.client);
  const cameraState = useZoomStore((store) => store.localMedia.camera);

  const onClick = useAsyncCallback(async () => {
    if (cameraState === VideoCapturingState.Started) {
      await client.getMediaStream().stopVideo();
    } else {
      await client.getMediaStream().startVideo();
    }
  }, [cameraState, client]);

  return (
    <button onClick={onClick} type='button'>
      {cameraState === VideoCapturingState.Started ? 'Stop Video' : 'Start Video'}
    </button>
  );
};
