import {
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValue,
} from '~/design/components/Select';
import { useAsyncCallback } from '~/hooks/useAsyncCallback';
import { useZoomStore } from '~/lib/zoom/state';

export interface CameraSelectorProps {
  showValue?: boolean;
}

export const CameraSelector = ({ showValue }: CameraSelectorProps) => {
  const cameras = useZoomStore((store) => store.devices.cameras);
  const value = useZoomStore((store) => store.devices.activeCameraId);
  const client = useZoomStore((store) => store.client);

  const onCameraChange = useAsyncCallback(
    async (id: string) => {
      await client.getMediaStream().switchCamera(id);
    },
    [client],
  );

  return (
    <SelectRoot value={value} onValueChange={onCameraChange}>
      <SelectTrigger aria-label='Select a camera'>
        {showValue ? (
          <SelectValue placeholder='Select a camera' />
        ) : (
          <SelectValue asChild>
            <div />
          </SelectValue>
        )}
        <SelectIcon>▼</SelectIcon>
      </SelectTrigger>
      <SelectContent>
        {/* Ensure one item at least is always shown */}
        {!cameras.some((cam) => cam.deviceId === 'default') && (
          <SelectItem value='default'>Default</SelectItem>
        )}
        {cameras.map((camera) => (
          <SelectItem value={camera.deviceId} key={camera.deviceId}>
            {camera.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};
