import { createContext, forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import type { HTMLAttributes, ReactNode } from 'react';

import { useMergedRef } from '~/hooks/useMergedRef';

import type { ParticipantLayout } from './ParticipantLayout';

const participantLayoutContext = createContext<ParticipantLayout | null>(null);

type LayoutConstructor<T extends ParticipantLayout = ParticipantLayout> = new () => T;

export const ParticipantLayoutRoot = forwardRef<
  HTMLDivElement,
  { children: ReactNode; className?: string; Layout: LayoutConstructor }
>(({ children, Layout, ...rest }, ref) => {
  const [layout, setLayout] = useState(() => new Layout());
  useEffect(() => {
    setLayout(new Layout());
  }, [Layout]);
  const finalRef = useMergedRef(ref, layout.setRoot);

  return (
    <participantLayoutContext.Provider value={layout}>
      <div
        ref={finalRef}
        data-participants-root
        css={{
          position: 'relative',
        }}
        {...rest}
      >
        {children}
      </div>
    </participantLayoutContext.Provider>
  );
});
ParticipantLayoutRoot.displayName = 'ParticipantLayoutRoot';

export const ParticipantLayoutItem = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    participantId: number;
    index: number;
    children: ReactNode;
  }
>(({ participantId, index, children, ...rest }, outerRef) => {
  const layout = useContext(participantLayoutContext);
  if (!layout) {
    throw new Error('ParticipantLayoutItem must be inside a ParticipantLayoutRoot');
  }

  const ref = useCallback(
    (el: HTMLDivElement) => {
      layout.setItem(`${participantId}`, el, index);
    },
    [participantId, layout, index],
  );

  const finalRef = useMergedRef(ref, outerRef);

  return (
    <div
      ref={finalRef}
      data-id={participantId}
      data-index={index}
      data-participants-item
      data-transition-container
      css={{
        position: 'absolute',
        // default size is 0; this will be overridden by the layout
        width: 0,
        height: 0,
      }}
      {...rest}
    >
      {children}
    </div>
  );
});
ParticipantLayoutItem.displayName = 'ParticipantLayoutItem';
