import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './layout/App';

import './index.css';

const root = document.getElementById('root');
if (!root) {
  throw new Error(`Missing root element!`);
}

ReactDOM.createRoot(root).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
