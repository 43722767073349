import { useFormikContext } from 'formik';
import type { HTMLAttributes } from 'react';

export const SubmitButton = ({ children, ...props }: HTMLAttributes<HTMLButtonElement>) => {
  const { isSubmitting } = useFormikContext<unknown>();

  return (
    <button type='submit' {...props} disabled={isSubmitting}>
      {isSubmitting ? 'Loading...' : children}
    </button>
  );
};
