import { ApolloProvider } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { StatsigProvider } from 'statsig-react';

import config from '~/config';
import { apolloClient } from '~/lib/apollo/apolloClient';

import { Router } from './Router';

if (config.env === 'production') {
  datadogRum.init(config.datadog.rum);
  datadogRum.startSessionReplayRecording();
}

const App = () => (
  <ApolloProvider client={apolloClient}>
    <StatsigProvider
      user={/* TODO: populate user */ {}}
      sdkKey={config.statsig.clientKey}
      options={config.statsig.options}
      waitForInitialization
    >
      <Router />
    </StatsigProvider>
  </ApolloProvider>
);

export default App;
