import { useField } from 'formik';
import { forwardRef, useId } from 'react';
import type { InputHTMLAttributes, Ref } from 'react';

export type TextFieldProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'id'
  | 'autoComplete'
  | 'required'
  | 'autoFocus'
  | 'placeholder'
  | 'type'
  | 'onChange'
  | 'onFocus'
  | 'onBlur'
> & {
  name: string;
  label?: string;
  inputRef?: Ref<HTMLInputElement>;
};

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ id, name, onChange, onFocus, onBlur, label }, ref) => {
    const [props] = useField({
      name,
      onChange,
      onFocus,
      onBlur,
    });

    const defaultId = useId();
    const usedId = id ?? defaultId;

    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
        ref={ref}
      >
        {label ? <label htmlFor={usedId}>{label}</label> : null}
        <input {...props} id={usedId} />
      </div>
    );
  },
);
TextField.displayName = 'TextField';
