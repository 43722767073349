const env = import.meta.env['MODE'] || 'development';
const isProd = import.meta.env.PROD;
const isDev = import.meta.env.DEV;

const config = {
  env,
  isProd,
  isDev,
  api: {
    host: import.meta.env['VITE_API_HOST'] as string,
  },
  statsig: {
    clientKey: import.meta.env['VITE_STATSIG_CLIENT_KEY'] as string,
    options: {
      environment: {
        tier: env,
      },
      localMode: env === 'test',
    },
  },
  zoom: {
    appKey: import.meta.env['VITE_ZOOM_APP_KEY'] as string,
  },
  datadog: {
    rum: {
      env,
      applicationId: import.meta.env['VITE_DD_RUM_APP_ID'] as string,
      clientToken: import.meta.env['VITE_DD_RUM_CLIENT_TOKEN'] as string,
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      site: 'us5.datadoghq.com',
      service: 'venue',
      trackUserInteractions: true,
      // version: '1.0.0',
    },
  },
};

export default config;
