import { ParticipantLayout } from './ParticipantLayout';

export class HoneycombLayout extends ParticipantLayout {
  private itemSize = 80;
  private itemGap = 8;

  constructor() {
    super([]);
  }

  reflow = () => {
    const basicParticipants = this.itemElements;
    // for sufficiently horizontal layouts, flow horizontally
    if (this.rootWidth > this.rootHeight * 1.75) {
      const rowCount = this.reflowHorizontal(basicParticipants);
      if (this.root) {
        this.root.style.setProperty(
          'height',
          `${this.itemGap * 2 + rowCount * (this.itemSize + this.itemGap)}px`,
        );
        this.root.style.removeProperty('width');
      }
    } else {
      const columnCount = this.reflowVertical(basicParticipants);
      if (this.root) {
        this.root.style.setProperty(
          'width',
          `${this.itemGap * 2 + columnCount * (this.itemSize + this.itemGap)}px`,
        );
        this.root.style.removeProperty('height');
      }
    }
  };

  private reflowHorizontal = (basicParticipants: { id: string; element: HTMLElement }[]) => {
    // compute number of rows by determining how many items fit horizontally

    // subtract 1 gap (the starting gap)
    const spaceToDivide = this.rootWidth - this.itemGap;
    // divide by each element plus its gap
    const columnCount = Math.max(1, Math.floor(spaceToDivide / (this.itemSize + this.itemGap)));
    let rowCount = 0;
    let totalItemsProcessed = 0;
    // odd-numbered rows have 1 fewer item
    while (totalItemsProcessed < basicParticipants.length) {
      if (rowCount % 2 === 0) {
        totalItemsProcessed += columnCount;
      } else {
        totalItemsProcessed += columnCount - 1;
      }
      rowCount++;
    }

    const totalHeight = rowCount * (this.itemSize + this.itemGap);
    const unusedSpots = Math.max(0, columnCount - basicParticipants.length);
    const totalWidth = (columnCount - unusedSpots) * (this.itemSize + this.itemGap);

    // we also want to center the whole grouping horizontally and vertically
    // within the container
    const centerHOffset = Math.max(0, (this.rootWidth - totalWidth) / 2);
    // vertical space depends on if the items are auto size
    const verticalSpace = totalHeight + this.itemGap * 2;
    const centerVOffset = Math.max(0, (verticalSpace - totalHeight) / 2);

    let row = 0;
    let col = 0;
    basicParticipants.forEach((item) => {
      // odd-numbered rows are horizontally offset by 1/2 item size
      const isOddRow = row % 2 === 1;
      const hOffset = isOddRow ? this.itemSize / 2 : 0;

      const x = Math.round(
        this.itemGap + this.itemSize / 2 + col * (this.itemSize + this.itemGap) + hOffset + centerHOffset,
      );
      const y = Math.round(
        this.itemGap + this.itemSize / 2 + row * (this.itemSize + this.itemGap) + centerVOffset,
      );
      const size = this.itemSize;

      const { element } = item;

      element.style.top = `${y}px`;
      element.style.left = `${x}px`;
      element.style.width = `${size}px`;
      element.style.height = `${size}px`;

      col++;
      if ((isOddRow && col === columnCount - 1) || (!isOddRow && col === columnCount)) {
        row++;
        col = 0;
      }
    });

    return rowCount;
  };

  private reflowVertical = (basicParticipants: { id: string; element: HTMLElement }[]) => {
    // compute number of columns by determining how many items fit horizontally

    // subtract 1 gap (the starting gap)
    const spaceToDivide = this.rootWidth - this.itemGap;
    // divide by each element plus its gap
    const rowCount = Math.max(1, Math.floor(spaceToDivide / (this.itemSize + this.itemGap)));
    let columnCount = 0;
    let totalItemsProcessed = 0;
    // odd-numbered columns have 1 fewer item
    while (totalItemsProcessed < basicParticipants.length) {
      if (columnCount % 2 === 0) {
        totalItemsProcessed += rowCount;
      } else {
        totalItemsProcessed += rowCount - 1;
      }
      columnCount++;
    }

    const totalWidth = columnCount * (this.itemSize + this.itemGap);
    const unusedSpots = Math.max(0, rowCount - basicParticipants.length);
    const totalHeight = (rowCount - unusedSpots) * (this.itemSize + this.itemGap);

    // we also want to center the whole grouping horizontally and vertically
    // within the container
    const centerHOffset = Math.max(0, (this.rootWidth - totalWidth) / 2);
    const centerVOffset = Math.max(0, (this.rootHeight - totalHeight) / 2);

    basicParticipants.forEach((item, index) => {
      // odd-numbered columns are vertically offset by 1/2 item size
      const col = Math.floor(index / columnCount);
      const row = index % rowCount;
      const isOddColumn = col % 2 === 1;
      const vOffset = isOddColumn ? this.itemSize / 2 : 0;

      const x = Math.round(this.itemSize / 2 + col * (this.itemSize + this.itemGap) + centerHOffset);
      const y = Math.round(
        this.itemSize / 2 + row * (this.itemSize + this.itemGap) + vOffset + centerVOffset,
      );
      const size = this.itemSize;

      const { element } = item;

      element.style.top = `${y}px`;
      element.style.left = `${x}px`;
      element.style.width = `${size}px`;
      element.style.height = `${size}px`;
    });

    return columnCount;
  };
}
