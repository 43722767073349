import { debounce } from '~/lib/util/debounce';

export abstract class ParticipantLayout {
  constructor(protected observedItemAttributes: string[] = []) {}

  private rootResizeObserver = new ResizeObserver((entries) => {
    const entry = entries[0];
    if (entry) {
      // Firefox 69-91 implements `contentBoxSize` as a single content rect,
      // rather than an array
      const originalContentBoxSize = entry.contentBoxSize as ResizeObserverSize[] | ResizeObserverSize;
      const contentBoxSize = Array.isArray(originalContentBoxSize)
        ? originalContentBoxSize[0]
        : originalContentBoxSize;
      if (!contentBoxSize) return;

      if (this.rootWidth !== contentBoxSize.inlineSize || this.rootHeight !== contentBoxSize.blockSize) {
        this.rootWidth = contentBoxSize.inlineSize;
        this.rootHeight = contentBoxSize.blockSize;

        this.debouncedReflow();
      }
    }
  });

  private itemMutationObserver = new MutationObserver(() => {
    this.debouncedReflow();
  });

  protected root: HTMLDivElement | null = null;
  protected canvas: HTMLCanvasElement | null = null;
  protected itemElements: {
    id: string;
    element: HTMLElement;
    index: number | undefined;
  }[] = [];
  protected rootWidth = 0;
  protected rootHeight = 0;

  protected get context2d() {
    return this.canvas?.getContext('2d');
  }

  setItem = (id: string, element: HTMLElement | null, index?: number) => {
    this.itemElements = this.itemElements.filter((item) => item.id !== id);
    if (element) {
      this.itemElements.push({ element, id, index });
      if (index) {
        this.itemElements.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
      }
      if (this.observedItemAttributes.length) {
        this.itemMutationObserver.observe(element, {
          attributes: true,
          attributeFilter: this.observedItemAttributes,
          attributeOldValue: false,
          childList: false,
          subtree: false,
        });
      }
    }
    this.doReflow();
  };

  setRoot = (element: HTMLDivElement | null) => {
    this.root = element;

    if (element) {
      this.rootResizeObserver.observe(element);
      this.rootWidth = element.clientWidth;
      this.rootHeight = element.clientHeight;
      this.canvas = document.createElement('canvas');
      this.canvas.style.position = 'absolute';
      this.canvas.style.top = '0';
      this.canvas.style.left = '0';
      this.canvas.style.width = '100%';
      this.canvas.style.height = '100%';
      this.canvas.style.pointerEvents = 'none';
      element.prepend(this.canvas);
    } else {
      this.rootResizeObserver.disconnect();
      if (this.canvas) {
        this.canvas.remove();
        this.canvas = null;
      }
    }
    this.doReflow();
  };

  protected doReflow = () => {
    if (!this.root) return;
    if (this.rootWidth === 0 && this.rootHeight === 0) return;
    if (this.canvas) {
      this.canvas.width = this.rootWidth;
      this.canvas.height = this.rootHeight;
    }

    if (!this.itemElements.length) return;

    this.reflow();
  };

  protected abstract reflow(): void;

  debouncedReflow = debounce(this.doReflow, 10);
}
