import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { CallScene } from '~/scenes/CallScene';
import { JoinCallScene } from '~/scenes/JoinCallScene';

import { Call } from './routes/Call';
import { Home } from './routes/Home';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/call',
    element: <Call />,
    children: [
      {
        index: true,
        element: <JoinCallScene />,
      },
      {
        path: ':topic',
        element: <CallScene />,
      },
    ],
  },
]);

export const Router = () => <RouterProvider router={router} />;
