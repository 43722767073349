import { useAsyncCallback } from '~/hooks/useAsyncCallback';
import { useZoomStore } from '~/lib/zoom/state';

export const AudioToggleButton = () => {
  const client = useZoomStore((store) => store.client);
  const micState = useZoomStore((store) => store.localMedia.mic);

  const onClick = useAsyncCallback(async () => {
    if (micState) {
      await client.getMediaStream().stopAudio();
    } else {
      await client.getMediaStream().startAudio();
    }
  }, [micState, client]);

  return (
    <button onClick={onClick} type='button'>
      {micState ? 'Stop Audio' : 'Start Audio'}
    </button>
  );
};
