import ZoomVideo, { ConnectionState, VideoCapturingState } from '@zoom/videosdk';
import type { Participant } from '@zoom/videosdk';
import { create } from 'zustand';
import { combine, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import config from '~/config';

import { logger } from '../logger';

export const client = ZoomVideo.createClient();
/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,
 @typescript-eslint/no-explicit-any
-- putting this on window is useful for debugging but having it typed might
encourage people to actually use it from window, which is undesired
*/
(window as unknown as any).client = client;

async function getToken({ topic, name, password }: { topic: string; name: string; password: string }) {
  // TODO: update this to a real API.
  const res = await fetch(`${config.api.host}/rpc/zoom/token`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      topic,
      password,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!res.ok) {
    throw new Error(`Failed to get media token: ${res.statusText}`);
  }
  const { signature: token } = await (res.json() as Promise<{ signature: string }>);

  return token;
}

export enum InitializationState {
  NotInitialized,
  Initializing,
  Initialized,
  Error,
}

export const useZoomStore = create(
  subscribeWithSelector(
    immer(
      combine(
        /**
         * Base state data
         */
        {
          initializationState: InitializationState.NotInitialized,
          connectionState: ConnectionState.Closed,
          loading: false,
          client,
          /** eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
          /** not sure how to do this here?? */
          users: {} as { [key: string]: Participant },
          localMedia: {
            camera: VideoCapturingState.Stopped,
            mic: false,
            screenShare: null as (HTMLVideoElement | HTMLCanvasElement) | null,
          },
          devices: {
            cameras: new Array<{ deviceId: string; label: string }>(),
            mics: new Array<{ deviceId: string; label: string }>(),
            activeCameraId: 'default',
            activeMicId: 'default',
          },
          encoding: {
            video: false,
            audio: false,
            share: false,
          },
          decoding: {
            video: false,
            audio: false,
            share: false,
          },
          activeSpeakerIds: new Array<number>(),
          screenShareUserId: null as number | null,
        },
        /**
         * Derived state and state modifier methods.
         */
        (set, get) => {
          // extracting this helper because I'm having trouble
          // figuring out which exact event to listen to for
          // where devices are suddenly available
          function refreshDevices() {
            set((current) => {
              current.devices.cameras = client.getMediaStream().getCameraList();
              current.devices.mics = client.getMediaStream().getMicList();
              current.devices.activeMicId = client.getMediaStream().getActiveMicrophone();
              current.devices.activeCameraId = client.getMediaStream().getActiveCamera();
            });
          }

          /**
           * Here we can subscribe to some global events to keep the
           * state updated
           */
          client.on('connection-change', ({ state }) => {
            logger.debug('Connection change', state);
            set({ connectionState: state });
            refreshDevices();
          });
          client.on('user-added', (payload) => {
            logger.debug('User added', payload);
            // sigh... the client doesn't have the user data until after
            // this event is fired, so...
            queueMicrotask(() => {
              set((state) => {
                for (const participant of payload) {
                  const user = client.getUser(participant.userId);
                  if (user) {
                    state.users[participant.userId] = user;
                  }
                }
              });
            });
          });
          client.on('user-removed', (payload) => {
            logger.debug('User removed', payload);
            set((state) => {
              for (const participant of payload) {
                delete state.users[participant.userId];
              }
            });
          });
          client.on('user-updated', (payload) => {
            logger.debug('User updated', payload);
            set((state) => {
              for (const participant of payload) {
                const user = client.getUser(participant.userId);
                if (user) {
                  state.users[participant.userId] = user;
                }
              }
            });
          });
          client.on('video-capturing-change', ({ state }) => {
            set((current) => {
              current.localMedia.camera = state;
            });
          });
          client.on('media-sdk-change', (payload) => {
            set((current) => {
              if (payload.action === 'encode') {
                current.encoding[payload.type] = payload.result === 'success';
              } else {
                current.decoding[payload.type] = payload.result === 'success';
              }
            });
            // this appears to be when devices first become available
            refreshDevices();
          });
          client.on('active-speaker', (payload) => {
            set((current) => {
              logger.debug('Active speakers', payload);
              current.activeSpeakerIds = payload.map((speaker) => speaker.userId);
            });
          });
          client.on('current-audio-change', (payload) => {
            set((current) => {
              // not sure if this logic is right or not honestly
              current.localMedia.mic = ['join', 'unmuted', 'passive', 'active'].includes(payload.action);
            });
          });
          client.on('device-change', refreshDevices);
          client.on('active-share-change', (payload) => {
            set((current) => {
              current.screenShareUserId = payload.userId;
            });
          });
          client.on('auto-play-audio-failed', () => {
            logger.warn('Failed to auto-play audio');
          });

          const initialize = async () => {
            /* eslint-disable-next-line
@typescript-eslint/no-unnecessary-condition
    -- Although the result of get() is defined within the scope of store
    methods, it's not necessarily defined when this method is called inline.
*/
            const currentState = get()?.initializationState ?? InitializationState.NotInitialized;
            if (
              currentState !== InitializationState.NotInitialized &&
              currentState !== InitializationState.Error
            ) {
              // avoid double-initializing
              return () => {};
            }

            logger.debug('Initializing Zoom');
            try {
              await client.init('en-US', `${window.location.origin}/lib`, {
                webEndpoint: 'zoom.us',
                enforceMultipleVideos: true,
                stayAwake: true,
              });
              void ZoomVideo.getDevices().then(() => {
                refreshDevices();
              });
              set({ initializationState: InitializationState.Initialized });

              return () => {
                ZoomVideo.destroyClient();
                set({ initializationState: InitializationState.NotInitialized });
              };
            } catch (err) {
              logger.error('Failed to initialize Zoom', err);
              set({ initializationState: InitializationState.Error });

              return () => {};
            }
          };

          // convenience: go ahead and initialize Zoom so it doesn't
          // have to be done in React lifecycle...
          void initialize();

          return {
            /**
             * Join a call. Requires the topic of the call (like an ID), the
             * name of the participant, and a password.
             */
            join: async ({ topic, name, password }: { topic: string; name: string; password: string }) => {
              set({ loading: true });
              try {
                const token = await getToken({
                  topic,
                  name,
                  password,
                });
                await client.join(topic, token, name, password);
                // await client.getMediaStream().startVideo();
                // await client.getMediaStream().startAudio();
              } finally {
                set({ loading: false });
              }
            },

            /**
             * Leave any current call
             */
            leave: () => client.leave(),

            /**
             * Prepares the Zoom Client to connect to a call. Returns a function
             * to teardown the client.
             */
            initialize,
            /**
             * Manually teardown any active Zoom Client.
             */
            destroy: () => {
              ZoomVideo.destroyClient();
              set({ initializationState: InitializationState.NotInitialized });
            },
            /**
             * Start local screenshare. This will return a promise for an
             * HTML element that you must then render somewhere on the page! I
             * wish we didn't have to couple it so tightly to the DOM, but that
             * 's how it is. The element will be available in localMedia state
             * if you need it elsewhere.
             */
            startScreenShare: async () => {
              const supportsMediaStreamTrackProcessor = 'MediaStreamTrackProcessor' in window;
              let targetElement: HTMLVideoElement | HTMLCanvasElement;
              if (supportsMediaStreamTrackProcessor) {
                targetElement = document.createElement('video');
              } else {
                targetElement = document.createElement('canvas');
              }
              // the element has to be in the DOM for the SDK to work :(
              // since we have no opinion at this point about where the
              // screenshare is going to be rendered, we have to hide
              // it with CSS and put it in the document for now.
              // targetElement.style.visibility = 'hidden';

              targetElement.style.display = 'none';

              document.body.appendChild(targetElement);
              // fork this async and return the element immediately
              await client
                .getMediaStream()
                // I wish we could disable exactOptionalPropertyTypes for
                // function parameters.
                // @ts-expect-error -- Zoom SDK types for element are wrong,
                // it accepts a video element.
                .startShareScreen(targetElement, undefined);

              set((current) => {
                current.localMedia.screenShare = targetElement;
              });

              return targetElement;
            },

            stopScreenShare: async () => {
              await client.getMediaStream().stopShareScreen();
              set((current) => {
                current.localMedia.screenShare = null;
              });
            },
          };
        },
      ),
    ),
  ),
);

/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,
 @typescript-eslint/no-explicit-any
-- putting this on window is useful for debugging but having it typed might
encourage people to actually use it from window, which is undesired
*/
(window as unknown as any).zoomStore = useZoomStore;

declare global {
  interface Window {
    // just need to document the existence of this API in some browsers
    MediaStreamTrackProcessor: unknown;
  }
}
