import type { Participant } from '@zoom/videosdk';

import { useZoomStore } from '~/lib/zoom/state';

import { HoneycombGrid, HoneycombItem } from './HoneycombGrid';
import { ParticipantVideo } from './ParticipantVideo';
import { ScreenShareVideo } from './ScreenShareVideo';

export const VideoGrid = () => {
  const users = useZoomStore((store) => store.users);
  const speakerIds = useZoomStore((store) => store.activeSpeakerIds);
  const stableOrderUsers = Object.values(users).sort((a, b) => a.userId - b.userId);

  const speakers = stableOrderUsers.reduce<Participant[]>((acc, user) => {
    if (speakerIds.includes(user.userId)) {
      acc.push(user);
    }

    return acc;
  }, []);

  return (
    <div
      css={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
      }}
    >
      <SpeakerGrid participants={speakers} />
      <NonSpeakerGrid participants={stableOrderUsers} />
    </div>
  );
};

const SpeakerGrid = ({ participants }: { participants: Participant[] }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'row',
      flex: '1 1 0',
      minHeight: 0,
    }}
  >
    <ScreenShareVideo
      css={{
        flex: '1 1 auto',
      }}
    />
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gridAutoRows: '1fr',
        gap: '1rem',
        overflow: 'hidden',
        padding: '1rem',
        position: 'relative',
        flex: '1 1 auto',
      }}
    >
      {participants.map((user) => (
        <div
          key={user.userId}
          css={{
            width: '100%',
            height: '100%',
          }}
        >
          <ParticipantVideo participant={user} promoted />
        </div>
      ))}
    </div>
  </div>
);

const NonSpeakerGrid = ({ participants }: { participants: Participant[] }) => {
  const speakerIds = useZoomStore((store) => store.activeSpeakerIds);

  return (
    <HoneycombGrid
      css={{
        flex: `0 0 auto`,
        width: '100%',
      }}
    >
      {participants.map((user, index) => {
        const isEmptySeat = speakerIds.includes(user.userId);

        return <HoneycombItem key={user.userId} index={index} participant={user} isEmptySeat={isEmptySeat} />;
      })}
    </HoneycombGrid>
  );
};
